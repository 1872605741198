import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Link ,useLocation } from 'react-router-dom'

import WhatsAppButton from './Whatsapp';
import { useNavigate } from 'react-router-dom';
import { CiLocationArrow1 } from "react-icons/ci";
import { FaSquarePhone } from "react-icons/fa6";
import { FaWhatsapp } from "react-icons/fa6";
import { FaRegEnvelope } from "react-icons/fa";
const Footer = () => {

  const navigate = useNavigate();
  const location = useLocation(); 
  const handlenavigate = () => {
    navigate('/products');
  }
  return (
    <>
     {location.pathname !== '/products' && (
      <div className='fixed point2 blink' onClick={handlenavigate}>
        <img src={require('../../assets/images/quickpurchase.png')} className='img-fluid priceicn2 float-right' alt='special price' />
      </div>
       )}
      <div className='fixed point'>
        <WhatsAppButton name="JC Pyro Mart" phoneNumber="919003885080" />
      </div>
      <a href="https://maps.app.goo.gl/Kgs3nH8Msh8xNFBo8">
        <div className="fixed point3" style={{ cursor: 'pointer' }}>
          <img src={require('../../assets/images/googlelocation.png')} className='img-fluid priceicn float-left' alt='special price' />
        </div>
      </a>
      <>
        <div className='footer-bg foot-pad'>
          <Container className='z'>
            <Row>
              <Col lg='12' className='py-4'>
                <Row className='justify-content-center'>
                <Col lg='3' className="py-3">
                <div>
                    <img src={require('../../assets/images/storelogo.png')} className='img-fluid logo-circle' alt='JC Pyro Mart' />
                  </div>
                    <div className='regular'>Light up your Diwali celebrations with our spectacular range of crackers! Enjoy unbeatable discounts and make this festival of lights truly memorable</div>
                  </Col>
                  <Col lg='5' className="align-self-center py-3">
                  <h4 className='bold'> Contact Us</h4>
                    <ul className='list-unstyled'>
                      <li className='mx-3 pb-3 smallfnt'>
                        <p><CiLocationArrow1 /></p>
                          <div className='text_one'>
                            No.3/1362/30, Bharathi Nagar, Sattur Road,
                          Sivakasi - 626189</div>
                      </li>
                      <li className='mx-3 pb-3 smallfnt'>
                          <p> <FaSquarePhone /></p>
                          <div className='text_one'>+91 9003885080</div>
                      </li>
                      <li className='mx-3 pb-3 smallfnt'>
                          <p><FaWhatsapp /></p>
                          <div className='text_one'>+91 9944726077</div>
                      </li>
                      <li className='mx-3 pb-3 smallfnt'>
                        <p><FaRegEnvelope /></p>
                        <div className='text_one'>jcpyromart@gmail.com</div>
                      </li>
                    </ul>
                    
                  </Col>
                  <Col lg='3' className="align-self-center py-3">
                    <h4 className='bold'> Quick Links</h4>
                    <ul className='list-unstyled'>
                      <li className='mx-3 pb-3 smallfnt'><Link to='/'>Home</Link></li>
                      <li className='mx-3 pb-3 smallfnt'><Link to='/about'>About Us</Link></li>
                      <li className='mx-3 pb-3 smallfnt'><Link to='/products'>Products</Link></li>
                      <li className='mx-3 pb-3 smallfnt'><Link to='/safetytips'>Safety Tips</Link></li>
                      <li className='mx-3 pb-3 smallfnt'><Link to='/contactus'>Contact US</Link></li>
                    </ul>
                  </Col>
                </Row>
              </Col>
              <Col lg='3' className='py-3'>
            
              
              </Col>
              

            </Row>
          </Container>
        </div>
      </>
      <>
        <div className='mark-bg'>
          <Container>
            <Row>
              <Col lg='12' className='py-3'>
                <div className='text-center regular text-white'> Copyright © 2024,. JC Pyro Mart. All rights reserved </div>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    </>
  )
}

export default Footer